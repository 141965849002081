@include dark-mode(){.daterangepicker{background-color:lighten($dark, 5%);  border:inherit;
    &::before,
    &::after{  border-bottom-color:lighten($dark, 5%);  }
    td.available:hover,
    th.available:hover{  background-color:lighten($dark, 5%);  }
    td.in-range{  background-color:lighten($dark, 10%);    color:$white;  }
    td.off,
    td.off.in-range,
    td.off.start-date,
    td.off.end-date{  background-color:darken($dark, 5%);    color:$white;  }
    .ranges li:hover{  background-color:$dark;  }
    &.show-ranges.ltr .drp-calendar{  border-color:lighten($dark, 10%);
      &.left,
      &.right{    border-color:lighten($dark, 10%);      padding-top:0;    }
    }
    .drp-buttons{  border-color:lighten($dark, 10%);  }
    .calendar-table{  background-color:$dark;    border-color:lighten($dark, 10%);
      th,
      td{    color:$white;    }
      .next span,
      .prev span{    border-color:$white;    }
    }
    select.hourselect,
    select.minuteselect,
    select.secondselect,
    select.ampmselect{  background-color:$dark;    border-color:lighten($dark, 10%);  }
  }
}
