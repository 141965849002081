body.iframe-mode{.main-sidebar{display:none;}
  .content-wrapper{margin-left:0 !important;  margin-top:0 !important;  padding-bottom:0 !important;}
  .main-header,
  .main-footer{display:none;}
}
body.iframe-mode-fullscreen{overflow:hidden;
  &.layout-navbar-fixed .wrapper .content-wrapper{margin-top:0 !important;}
}
.content-wrapper{height:100%;
  &.iframe-mode{.btn-iframe-close{  color:$danger;    position:absolute;    line-height:1;    right:.125rem;    top:.125rem;    z-index:10;    visibility:hidden;
      &:hover,
      &:focus{    animation-name:fadeIn;      animation-duration:$transition-speed;      animation-fill-mode:both;      visibility:visible;    }
      @include on-touch-device(){    visibility:visible;    }
    }
    .navbar-nav{  overflow-y:auto;    width:100%;
      .nav-link{    white-space:nowrap;    }
      .nav-item{    position:relative;
        &:hover,
        &:focus{      .btn-iframe-close{        animation-name:fadeIn;          animation-duration:$transition-speed;          animation-fill-mode:both;          visibility:visible;
            @include on-touch-device(){          visibility:visible;          }
          }
        }
      }
    }
    .tab-content{  position:relative;  }
    .tab-pane + .tab-empty{  display:none;  }
    .tab-empty{  width:100%;    display:flex;    justify-content:center;    align-items:center;  }
    .tab-loading{  position:absolute;    top:0;    left:0;    width:100%;    display:none;    background-color:$main-bg;
     >div{    display:flex;      justify-content:center;      align-items:center;      width:100%;      height:100%;    }
    }
    iframe{  border:0;    width:100%;    height:100%;    margin-bottom:-8px;
      .content-wrapper{    padding-bottom:0 !important;    }
    }
    body.iframe-mode-fullscreen &{  position:absolute;    left:0;    top:0;    right:0;    bottom:0;    margin-left:0 !important;    height:100%;    min-height:100%;    z-index:$zindex-main-sidebar + 10;  }
  }
}
.permanent-btn-iframe-close{.btn-iframe-close{animation:none !important;  visibility:visible !important;  opacity:1;}
}
@include dark-mode (){.content-wrapper.iframe-mode{.tab-loading{  background-color:$dark;  }
  }
}
