//
// Mixins:Animation
//

@keyframes flipInX{0%{transform:perspective(400px) rotate3d(1, 0, 0, 90deg);  transition-timing-function:ease-in;  opacity:0;}
  40%{transform:perspective(400px) rotate3d(1, 0, 0, -20deg);  transition-timing-function:ease-in;}
  60%{transform:perspective(400px) rotate3d(1, 0, 0, 10deg);  opacity:1;}
  80%{transform:perspective(400px) rotate3d(1, 0, 0, -5deg);}
  100%{transform:perspective(400px);}
}

@keyframes fadeIn{from{opacity:0;}
  to{opacity:1;}
}
@keyframes fadeOut{from{opacity:1;}
  to{opacity:0;}
}
@keyframes shake{0%{transform:translate(2px, 1px) rotate(0deg);}
  10%{transform:translate(-1px, -2px) rotate(-2deg);}
  20%{transform:translate(-3px, 0) rotate(3deg);}
  30%{transform:translate(0, 2px) rotate(0deg);}
  40%{transform:translate(1px, -1px) rotate(1deg);}
  50%{transform:translate(-1px, 2px) rotate(-1deg);}
  60%{transform:translate(-3px, 1px) rotate(0deg);}
  70%{transform:translate(2px, 1px) rotate(-2deg);}
  80%{transform:translate(-1px, -1px) rotate(4deg);}
  90%{transform:translate(2px, 2px) rotate(0deg);}
  100%{transform:translate(1px, -2px) rotate(-1deg);}
}
@keyframes wobble{0%{transform:none;}
  15%{transform:translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);}
  30%{transform:translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);}
  45%{transform:translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);}
  60%{transform:translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);}
  75%{transform:translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);}
  100%{transform:none;}
}
//
