//
// Mixins:Miscellaneous
//
// ETC
@mixin translate($x, $y){transform:translate($x, $y);}
// Different radius each side
@mixin border-radius-sides($top-left, $top-right, $bottom-left, $bottom-right){border-radius:$top-left $top-right $bottom-left $bottom-right;}
@mixin calc($property, $expression){#{$property}:calc(#{$expression});}
@mixin rotate($value){transform:rotate($value);}
@mixin animation($animation){animation:$animation;}
// Gradient background
@mixin gradient($color:#f5f5f5, $start:#eee, $stop:$white){background-color:$color;background-image:gradient(linear, left bottom, left top, color-stop(0, $start), color-stop(1, $stop));}
//
