//
// Misc:Miscellaneous
//
.border-transparent{border-color:transparent !important;}
// Description Blocks
.description-block{display:block;margin:10px 0;text-align:center;
  &.margin-bottom{margin-bottom:25px;}
 >.description-header{font-size:16px;  font-weight:600;  margin:0;  padding:0;}
 >.description-text{text-transform:uppercase;}
  // Description Block Extension
  .description-icon{font-size:16px;}
}
// List utility classes
.list-group-unbordered{> .list-group-item{border-left:0;  border-radius:0;  border-right:0;  padding-left:0;  padding-right:0;}
}
.list-header{color:$gray-600;font-size:15px;font-weight:700;padding:10px 4px;}
.list-seperator{background-color:$card-border-color;height:1px;margin:15px 0 9px;}
.list-link{> a{color:$gray-600;  padding:4px;
    &:hover{  color:$gray-900;  }
  }
}
// User block
.user-block{float:left;
  img{float:left;  height:40px;  width:40px;}
  .username,
  .description,
  .comment{display:block;  margin-left:50px;}
  .username{font-size:16px;  font-weight:600;  margin-top:-1px;}
  .description{color:$gray-600;  font-size:13px;  margin-top:-3px;}
  &.user-block-sm{img{  width:$img-size-sm;    height:$img-size-sm;  }
    .username,
    .description,
    .comment{  margin-left:40px;  }
    .username{  font-size:14px;  }
  }
}
// Image sizes
.img-sm,
.img-md,
.img-lg{float:left;}
.img-sm{height:$img-size-sm;width:$img-size-sm;
  + .img-push{margin-left:$img-size-sm + $img-size-push;}
}
.img-md{width:$img-size-md;height:$img-size-md;
  + .img-push{margin-left:$img-size-md + $img-size-push;}
}
.img-lg{width:$img-size-lg;height:$img-size-lg;
  + .img-push{margin-left:$img-size-lg + $img-size-push;}
}
// Image bordered
.img-bordered{border:3px solid $gray-500;padding:3px;}
.img-bordered-sm{border:2px solid $gray-500;padding:2px;}
// Rounded and Circle Images
.img-rounded{@include border-radius($border-radius);}
.img-circle{@include border-radius(50%);}
// Image sizes
.img-size-64,
.img-size-50,
.img-size-32{height:auto;}
.img-size-64{width:64px;}
.img-size-50{width:50px;}
.img-size-32{width:32px;}
// Block sizes
.size-32,
.size-40,
.size-50{display:block;text-align:center;}
.size-32{height:32px;line-height:32px;width:32px;}
.size-40{height:40px;line-height:40px;width:40px;}
.size-50{height:50px;line-height:50px;width:50px;}
// General attachemnt block
.attachment-block{background-color:$gray-100;border:1px solid $card-border-color;margin-bottom:10px;padding:5px;
  .attachment-img{float:left;  height:auto;  max-height:100px;  max-width:100px;}
  .attachment-pushed{margin-left:110px;}
  .attachment-heading{margin:0;}
  .attachment-text{color:$gray-700;}
}
// Overlays for Card, InfoBox & SmallBox
.card,
.overlay-wrapper,
.info-box,
.small-box{// Box overlay for LOADING STATE effect
 >.overlay,
 >.loading-img{height:100%;  left:0;  position:absolute;  top:0;  width:100%;}
  .overlay{@include border-radius($border-radius);  align-items:center;  background-color:rgba($white, .7);  display:flex;  justify-content:center;  z-index:50;
   >.fa,
   >.fas,
   >.far,
   >.fab,
   >.fal,
   >.fad,
   >.svg-inline--fa,
   >.ion{  color:$gray-800;  }
    &.dark{  background-color:rgba($black, .5);
     >.fa,
     >.fas,
     >.far,
     >.fab,
     >.fal,
     >.fad,
     >.svg-inline--fa,
     >.ion{    color:$gray-400;    }
    }
  }
}
.tab-pane{// Box overlay for LOADING STATE effect on Tab Panels
 >.overlay-wrapper{position:relative; >.overlay{  border-top-left-radius:0;    border-top-right-radius:0;    flex-direction:column;    margin-top:-$card-spacer-x;    margin-left:-$card-spacer-x;    height:calc(100% + 2 * #{$card-spacer-x});    width:calc(100% + 2 * #{$card-spacer-x});
      &.dark{    color:$white;    }
    }
  }
}
// Ribbon
.ribbon-wrapper{height:$ribbon-wrapper-size;overflow:hidden;position:absolute;right:-2px;top:-2px;width:$ribbon-wrapper-size;z-index:10;
  &.ribbon-lg{height:$ribbon-lg-wrapper-size;  width:$ribbon-lg-wrapper-size;
    .ribbon{  right:$ribbon-lg-right;    top:$ribbon-lg-top;    width:$ribbon-lg-width;  }
  }
  &.ribbon-xl{height:$ribbon-xl-wrapper-size;  width:$ribbon-xl-wrapper-size;
    .ribbon{  right:$ribbon-xl-right;    top:$ribbon-xl-top;    width:$ribbon-xl-width;  }
  }
  .ribbon{box-shadow:0 0 $ribbon-border-size rgba($black, .3);  font-size:$ribbon-font-size;  line-height:$ribbon-line-height;  padding:$ribbon-padding;  position:relative;  right:$ribbon-right;  text-align:center;  text-shadow:0 -1px 0 rgba($black, .4);  text-transform:uppercase;  top:$ribbon-top;  transform:rotate(45deg);  width:$ribbon-width;
    &::before,
    &::after{  border-left:$ribbon-border-size solid transparent;    border-right:$ribbon-border-size solid transparent;    border-top:$ribbon-border-size solid #9e9e9e;    bottom:-$ribbon-border-size;    content:"";    position:absolute;  }
    &::before{  left:0;  }
    &::after{  right:0;  }
  }
}
// Scroll To Top
.back-to-top{bottom:1.25rem;position:fixed;right:1.25rem;z-index:$zindex-control-sidebar + 1;
  &:focus{box-shadow:none;}
}
// Pre
pre{padding:.75rem;}
// Blockquotes styles
blockquote{background-color:$white;border-left:.7rem solid $primary;margin:1.5em .7rem;padding:.5em .7rem;
  .box &{background-color:$gray-200;}
  p:last-child{margin-bottom:0;}
  h1,
  h2,
  h3,
  h4,
  h5,
  h6{color:$primary;  font-size:1.25rem;  font-weight:600;}
  @each $color, $value in $theme-colors{&.quote-#{$color}{  border-color:$value;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6{    color:$value;    }
    }
  }
  @each $color, $value in $colors{&.quote-#{$color}{  border-color:$value;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6{    color:$value;    }
    }
  }
}

// Tab Custom Content
.tab-custom-content{border-top:$nav-tabs-border-width solid $nav-tabs-border-color;margin-top:.5rem;padding-top:.5rem;}
.nav + .tab-custom-content{border-top:none;border-bottom:$nav-tabs-border-width solid $nav-tabs-border-color;margin-top:0;margin-bottom:.5rem;padding-bottom:.5rem;}

// Badge BTN Style
.badge-btn{border-radius:$button-border-radius-xs;font-size:$button-font-size-xs;font-weight:400;padding:$button-padding-y-xs * 2 $button-padding-x-xs * 2;}
.badge-btn.badge-pill{padding:.375rem .6rem;}
@include dark-mode (){a:not(.btn):hover{color:lighten($link-color, 10%);}
  .attachment-block{background-color:lighten($dark, 3.75%);
    .attachment-text{  color:$gray-400;  }
  }
  blockquote{background-color:lighten($dark, 5%);
    @each $color, $value in $theme-colors{  &.quote-#{$color}{    border-color:$value;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{      color:$value;      }
      }
    }
    @each $color, $value in $colors{  &.quote-#{$color}{    border-color:$value;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{      color:$value;      }
      }
    }
  }
  .close,
  .mailbox-attachment-close{color:$gray-500;  text-shadow:0 1px 0 $gray-700;}
  .tab-custom-content{border-color:$gray-600;}
  .list-group-item{background-color:$dark;  border-color:$gray-600;}
}
