//
// Pages:E-commerce
//
// product image
.product-image{@include img-fluid ();width:100%;}
// product image thumbnails list
.product-image-thumbs{align-items:stretch;display:flex;margin-top:2rem;}
// product image thumbnail
.product-image-thumb{@include box-shadow($thumbnail-box-shadow);@include border-radius($thumbnail-border-radius);
  background-color:$thumbnail-bg;border:$thumbnail-border-width solid $thumbnail-border-color;display:flex;margin-right:1rem;max-width:6.5rem + ($thumbnail-padding * 2);padding:$thumbnail-padding * 2;
  img{@include img-fluid ();  align-self:center;}
  &:hover{opacity:.5;}
}
// product share
.product-share{a{margin-right:.5rem;}
}
