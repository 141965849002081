//
// Component:Info Box
//
.info-box{@include box-shadow($card-shadow);@include border-radius($border-radius);
  background-color:$white;display:flex;margin-bottom:map-get($spacers, 3);min-height:80px;padding:.5rem;position:relative;width:100%;
  .progress{background-color:rgba($black, .125);  height:2px;  margin:5px 0;
    .progress-bar{  background-color:$white;  }
  }
  .info-box-icon{@if $enable-rounded{  border-radius:$border-radius;  }
    align-items:center;  display:flex;  font-size:1.875rem;  justify-content:center;  text-align:center;  width:70px;
   >img{  max-width:100%;  }
  }
  .info-box-content{display:flex;  flex-direction:column;  justify-content:center;  line-height:1.8;  flex:1;  padding:0 10px;  overflow:hidden;}
  .info-box-number{display:block;  margin-top:.25rem;  font-weight:$font-weight-bold;}
  .progress-description,
  .info-box-text{display:block;  overflow:hidden;  text-overflow:ellipsis;  white-space:nowrap;}
  @each $name, $color in $theme-colors{.info-box{  .bg-#{$name},
      .bg-gradient-#{$name}{    color:color-yiq($color);
        .progress-bar{      background-color:color-yiq($color);      }
      }
    }
  }
  .info-box-more{display:block;}
  .progress-description{margin:0; }
  @include media-breakpoint-up(md){.col-xl-2 &,
    .col-lg-2 &,
    .col-md-2 &{  .progress-description{    display:none;    }
    }
    .col-xl-3 &,
    .col-lg-3 &,
    .col-md-3 &{  .progress-description{    display:none;    }
    }
  }
  @include media-breakpoint-up(lg){.col-xl-2 &,
    .col-lg-2 &,
    .col-md-2 &{  .progress-description{    @include font-size(.75rem);      display:block;    }
    }
    .col-xl-3 &,
    .col-lg-3 &,
    .col-md-3 &{  .progress-description{    @include font-size(.75rem);      display:block;    }
    }
  }
  @include media-breakpoint-up(xl){.col-xl-2 &,
    .col-lg-2 &,
    .col-md-2 &{  .progress-description{    @include font-size(1rem);      display:block;    }
    }
    .col-xl-3 &,
    .col-lg-3 &,
    .col-md-3 &{  .progress-description{    @include font-size(1rem);      display:block;    }
    }
  }
}
@include dark-mode (){.info-box{background-color:$dark;  color:$white;  @each $name, $color in $theme-colors-alt{  .info-box{    .bg-#{$name},
        .bg-gradient-#{$name}{      color:color-yiq($color);
          .progress-bar{        background-color:color-yiq($color);        }
        }
      }
    }
  }
}
