//
// Plugin:Select2
//
//Signle select
// .select2-container--default,
// .select2-selection{
//   &.select2-container--focus,
//   &:focus,
//   &:active{
//     outline:none;
//   }
// }
.select2-container--default{.select2-selection--single{border:$input-border-width solid $input-border-color;  //border-radius:$input-radius;  padding:($input-padding-y * 1.25) $input-padding-x;  height:$input-height;}
  &.select2-container--open{.select2-selection--single{  border-color:lighten($primary, 25%);  }
  }
  & .select2-dropdown{border:$input-border-width solid $input-border-color;  //border-radius:$input-radius;}
  & .select2-results__option{padding:6px 12px;  user-select:none;}
  & .select2-selection--single .select2-selection__rendered{padding-left:0;  //padding-right:0;  height:auto;  margin-top:-3px;}
  &[dir="rtl"] .select2-selection--single .select2-selection__rendered{padding-right:6px;  padding-left:20px;}
  & .select2-selection--single .select2-selection__arrow{height:31px;  right:6px;}
  & .select2-selection--single .select2-selection__arrow b{margin-top:0;}
  .select2-dropdown,
  .select2-search--inline{.select2-search__field{  border:$input-border-width solid $input-border-color;
      &:focus{    outline:none;      border:$input-border-width solid $input-focus-border-color;    }
    }
  }
  .select2-dropdown{&.select2-dropdown--below{  border-top:0;  }
    &.select2-dropdown--above{  border-bottom:0;  }
  }
  .select2-results__option{&[aria-disabled='true']{  color:$gray-600;  }
    &[aria-selected='true']{  $color:$gray-300;
      background-color:$color;
      &,
      &:hover{    color:color-yiq($color);    }
    }
  }
  .select2-results__option--highlighted{$color:$primary;  background-color:$color;  color:color-yiq($color);
    &[aria-selected]{  $color:darken($color, 3%);
      &,
      &:hover{    background-color:$color;      color:color-yiq($color);    }
    }
  }
  //Multiple select
  &{.select2-selection--multiple{  border:$input-border-width solid $input-border-color;    min-height:$input-height;
      &:focus{    border-color:$input-focus-border-color;    }
      .select2-selection__rendered{    padding:0 $input-padding-x * 0.5 $input-padding-y;      margin-bottom:-$input-padding-x * 0.5;
        li:first-child.select2-search.select2-search--inline{      width:100%;        margin-left:$input-padding-x * 0.5;
          .select2-search__field{        width:100% !important;        }
        }

        .select2-search.select2-search--inline{      .select2-search__field{        border:0;          margin-top:6px;        }
        }
      }
      .select2-selection__choice{    background-color:$primary;      border-color:darken($primary, 5%);      color:color-yiq($primary);      padding:0 10px;      margin-top:.31rem;    }
      .select2-selection__choice__remove{    color:rgba(255, 255, 255, 0.7);      float:right;      margin-left:5px;      margin-right:-2px;
        &:hover{      color:$white;      }
      }
      .text-sm &,
      &.text-sm{    .select2-search.select2-search--inline{      .select2-search__field{        margin-top:8px;        }
        }
        .select2-selection__choice{      margin-top:.4rem;      }
      }
    }
    &.select2-container--focus{  .select2-selection--single,
      .select2-selection--multiple{    border-color:$input-focus-border-color;    }
      .select2-search__field{    border:0;    }
    }
  }
  & .select2-selection--single .select2-selection__rendered li{padding-right:10px;}
  .input-group-prepend ~ &{.select2-selection{  border-bottom-left-radius:0;    border-top-left-radius:0;  }
  }
  .input-group>&:not(:last-child){.select2-selection{  border-bottom-right-radius:0;    border-top-right-radius:0;  }
  }
}
// Select2 Bootstrap4 Theme overrides
.select2-container--bootstrap4{&.select2-container--focus .select2-selection{box-shadow:none;}
}
// text-sm / form-control-sm override
select.form-control-sm ~{.select2-container--default{font-size:75%;}
}
.text-sm,
select.form-control-sm ~{.select2-container--default{.select2-selection--single{  height:$input-height-sm;
      .select2-selection__rendered{    margin-top:-.4rem;    }
      .select2-selection__arrow{    top:-.12rem;    }
    }
    .select2-selection--multiple{  min-height:$input-height-sm;
      .select2-selection__rendered{    padding:0 $input-padding-x-sm * 0.5 $input-padding-y-sm;      margin-top:-($input-padding-x-sm * .2);
        li:first-child.select2-search.select2-search--inline{      margin-left:$input-padding-x-sm * 0.5;      }
        .select2-search.select2-search--inline{      .select2-search__field{        margin-top:6px;        }
        }
      }
    }
  }
}
// Dropdown Fix inside maximized card
.maximized-card .select2-dropdown{z-index:9999;}
// Background colors (theme colors)
@each $name, $color in $theme-colors{@include select2-variant($name, $color);}
// Background colors (colors)
@each $name, $color in $colors{@include select2-variant($name, $color);}
@include dark-mode (){.select2-selection{background-color:$dark;  border-color:$gray-600;}
  .select2-container--disabled .select2-selection--single{background-color:lighten($dark, 7.5%);}
  .select2-selection--single{background-color:$dark;  border-color:$gray-600;
    .select2-selection__rendered{  color:$white;  }
  }
  .select2-dropdown .select2-search__field,
  .select2-search--inline .select2-search__field{background-color:$dark;  border-color:$gray-600;  color:white;}
  .select2-dropdown{background-color:$dark;  border-color:$gray-600;  color:white;}
  .select2-results__option[aria-selected="true"]{background-color:lighten($dark, 5%) !important;  color:$gray-300;}
  .select2-container .select2-search--inline .select2-search__field{background-color:transparent;  color:$white;}
  .select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice{color:$white;}
  // Background colors (theme colors)
  @each $name, $color in $theme-colors-alt{@include select2-variant($name, $color);}
  // Background colors (colors)
  @each $name, $color in $colors-alt{@include select2-variant($name, $color);}
}
