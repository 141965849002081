//
// Component:Button
//
.btn{&.disabled,
  &:disabled{cursor:not-allowed;}
  // Flat buttons
  &.btn-flat{@include border-radius(0);  border-width:1px;  box-shadow:none;}
  // input file btn
  &.btn-file{overflow:hidden;  position:relative;
   >input[type="file"]{  background-color:$white;    cursor:inherit;    display:block;    font-size:100px;    min-height:100%;    min-width:100%;    opacity:0;    outline:none;    position:absolute;    right:0;    text-align:right;    top:0;  }
  }
  .text-sm &{font-size:$font-size-sm !important;}
}
// Button color variations
.btn-default{background-color:$button-default-background-color;border-color:$button-default-border-color;color:$button-default-color;
  &:hover,
  &:active,
  &.hover{background-color:darken($button-default-background-color, 5%);  color:darken($button-default-color, 10%);}
  &.disabled,
  &:disabled{color:$button-default-color;  background-color:$button-default-background-color;}
}
.btn-outline-light{color:darken($light, 20%);border-color:darken($light, 20%);
  &.disabled,
  &:disabled{color:darken($light, 20%);  border-color:darken($light, 20%);}
}
// Application buttons
.btn-app{@include border-radius(3px);background-color:$button-default-background-color;border:1px solid $button-default-border-color;color:$gray-600;font-size:12px;height:60px;margin:0 0 10px 10px;min-width:80px;padding:15px 5px;position:relative;text-align:center;
  // Icons within the btn
 >.fa,
 >.fas,
 >.far,
 >.fab,
 >.fal,
 >.fad,
 >.svg-inline--fa,
 >.ion{display:block;  font-size:20px;}
 >.svg-inline--fa{margin:0 auto;}
  &:hover{background-color:$button-default-background-color;  border-color:darken($button-default-border-color, 20%);  color:$button-default-color;}
  &:active,
  &:focus{@include box-shadow(inset 0 3px 5px rgba($black, .125));}
  // The badge
 >.badge{font-size:10px;  font-weight:400;  position:absolute;  right:-10px;  top:-3px;}
}
// Extra Button Size
.btn-xs{@include button-size($button-padding-y-xs, $button-padding-x-xs, $button-font-size-xs, $button-line-height-xs, $button-border-radius-xs);}
@include dark-mode (){.btn-default,
  .btn-app{background-color:lighten($dark, 2.5%);  color:$white;  border-color:$gray-600;
    &:hover,
    &:focus{  background-color:lighten($dark, 5%);    color:$gray-300;    border-color:lighten($gray-600, 2.5%);  }
  }
  .btn-light{background-color:lighten($dark, 7.5%);  color:$white;  border-color:$gray-600;
    &:hover,
    &:focus{  background-color:lighten($dark, 10%);    color:$gray-300;    border-color:lighten($gray-600, 5%);  }
  }
  @each $color, $value in $theme-colors-alt{.btn-#{$color}{  @if $color == dark{    @include button-variant(darken($value, 5%), lighten($value, 10%));    } @else{    @include button-variant($value, $value);    }
    }
  }
  @each $color, $value in $theme-colors-alt{.btn-outline-#{$color}{  @if $color == dark{    @include button-outline-variant(darken($value, 20%));    } @else{    @include button-outline-variant($value);    }
    }
  }
}
