//
// Plugins:jQuery Mapael
//
.mapael{.map{position:relative;}
  .mapTooltip{@include reset-text();  @include border-radius($tooltip-border-radius);  @include font-size($tooltip-font-size);  background-color:$tooltip-bg;  color:$tooltip-color;  display:block;  max-width:$tooltip-max-width;  padding:$tooltip-padding-y $tooltip-padding-x;  position:absolute;  text-align:center;  word-wrap:break-word;  z-index:$zindex-tooltip;}
  .myLegend{background-color:$gray-100;  border:1px solid $gray-500;  padding:10px;  width:600px;}
  .zoomButton{background-color:$button-default-background-color;  border:1px solid $button-default-border-color;  border-radius:$btn-border-radius;  color:$button-default-color;  cursor:pointer;  font-weight:700;  height:16px;  left:10px;  line-height:14px;  padding-left:1px;  position:absolute;  text-align:center;  top:0;
    user-select:none;  width:16px;
    &:hover,
    &:active,
    &.hover{  background-color:darken($button-default-background-color, 5%);    color:darken($button-default-color, 10%);  }
  }
  .zoomReset{line-height:12px;  top:10px;}
  .zoomIn{top:30px;}
  .zoomOut{top:50px;}
}
