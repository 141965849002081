//
// Component:Users List
//
.users-list{@include list-unstyled ();
 >li{float:left;  padding:10px;  text-align:center;  width:25%;
    img{  @include border-radius(50%);    height:auto;    max-width:100%;  }
   >a:hover{  &,
      .users-list-name{    color:#999;    }
    }
  }
}
.users-list-name,
.users-list-date{display:block;}
.users-list-name{color:$gray-700;font-size:$font-size-sm;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.users-list-date{color:darken($gray-500, 20%);font-size:12px;}
@include dark-mode (){.users-list-name{color:$gray-400;}
  .users-list-date{color:$gray-500;}
}
