//
// Component:Forms
//
.form-group{&.has-icon{position:relative;
    .form-control{  padding-right:35px;  }
    .form-icon{  background-color:transparent;    border:0;    cursor:pointer;    font-size:1rem;    // margin-top:-3px;    padding:$input-btn-padding-y $input-btn-padding-x;    position:absolute;    right:3px;    top:0;  }
  }
}
// Button groups
.btn-group-vertical{.btn{&.btn-flat:first-of-type,
    &.btn-flat:last-of-type{  @include border-radius(0);  }
  }
}
// Support icons in form-control
.form-control-feedback{&.fa,
  &.fas,
  &.far,
  &.fab,
  &.fal,
  &.fad,
  &.svg-inline--fa,
  &.ion{line-height:$input-height;}
}
.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback{&.fa,
  &.fas,
  &.far,
  &.fab,
  &.fal,
  &.fad,
  &.svg-inline--fa,
  &.ion{line-height:$input-height-lg;}
}
.form-group-lg{.form-control + .form-control-feedback{&.fa,
    &.fas,
    &.far,
    &.fab,
    &.fal,
    &.fad,
    &.svg-inline--fa,
    &.ion{  line-height:$input-height-lg;  }
  }
}
.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback{&.fa,
  &.fas,
  &.far,
  &.fab,
  &.fal,
  &.fad,
  &.svg-inline--fa,
  &.ion{line-height:$input-height-sm;}
}
.form-group-sm{.form-control + .form-control-feedback{&.fa,
    &.fas,
    &.far,
    &.fab,
    &.fal,
    &.fad,
    &.svg-inline--fa,
    &.ion{  line-height:$input-height-sm;  }
  }
}
label:not(.form-check-label):not(.custom-file-label){font-weight:$font-weight-bold;}
.warning-feedback{@include font-size($form-feedback-font-size);color:theme-color("warning");display:none;margin-top:$form-feedback-margin-top;width:100%;}
.warning-tooltip{@include border-radius($form-feedback-tooltip-border-radius);@include font-size($form-feedback-tooltip-font-size);background-color:rgba(theme-color("warning"), $form-feedback-tooltip-opacity);color:color-yiq(theme-color("warning"));display:none;line-height:$form-feedback-tooltip-line-height;margin-top:.1rem;max-width:100%; // Contain to parent when possible
  padding:$form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;position:absolute;top:100%;z-index:5;}
.form-control{&.is-warning{border-color:theme-color("warning");
    @if $enable-validation-icons{  // padding-right:$input-height-inner;    // background-image:none;    // background-repeat:no-repeat;    // background-position:center right $input-height-inner-quarter;    // background-size:$input-height-inner-half $input-height-inner-half;  }
    &:focus{  border-color:theme-color("warning");    box-shadow:0 0 0 $input-focus-width rgba(theme-color("warning"), .25);  }
    ~ .warning-feedback,
    ~ .warning-tooltip{  display:block;  }
  }
}
// stylelint-disable-next-line selector-no-qualifying-type
textarea.form-control{&.is-warning{@if $enable-validation-icons{  padding-right:$input-height-inner;    background-position:top $input-height-inner-quarter right $input-height-inner-quarter;  }
  }
}
.custom-select{&.is-warning{border-color:theme-color("warning");
    @if $enable-validation-icons{  // padding-right:$custom-select-feedback-icon-padding-right;    // background:$custom-select-background, none $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;  }
    &:focus{  border-color:theme-color("warning");    box-shadow:0 0 0 $input-focus-width rgba(theme-color("warning"), .25);  }
    ~ .warning-feedback,
    ~ .warning-tooltip{  display:block;  }
  }
}

.form-control-file{&.is-warning{~ .warning-feedback,
    ~ .warning-tooltip{  display:block;  }
  }
}
.form-check-input{&.is-warning{~ .form-check-label{  color:theme-color("warning");  }
    ~ .warning-feedback,
    ~ .warning-tooltip{  display:block;  }
  }
}
.custom-control-input.is-warning{~ .custom-control-label{color:theme-color("warning");
    &::before{  border-color:theme-color("warning");  }
  }
  ~ .warning-feedback,
  ~ .warning-tooltip{display:block;}
  &:checked{~ .custom-control-label::before{  @include gradient-bg(lighten(theme-color("warning"), 10%));    border-color:lighten(theme-color("warning"), 10%);  }
  }
  &:focus{~ .custom-control-label::before{  box-shadow:0 0 0 $input-focus-width rgba(theme-color("warning"), .25);  }
    &:not(:checked) ~ .custom-control-label::before{  border-color:theme-color("warning");  }
  }
}
// custom file
.custom-file-input{&.is-warning{~ .custom-file-label{  border-color:theme-color("warning");  }
    ~ .warning-feedback,
    ~ .warning-tooltip{  display:block;  }
    &:focus{  ~ .custom-file-label{    border-color:theme-color("warning");      box-shadow:0 0 0 $input-focus-width rgba(theme-color("warning"), .25);    }
    }
  }
}
// body.text-sm support
body.text-sm{.input-group-text{font-size:$font-size-sm;}
}
// custom .form-control styles
.form-control,
.custom-select{&.form-control-border{border-top:0;  border-left:0;  border-right:0;  border-radius:0;  box-shadow:inherit;
    &.border-width-2{  border-bottom-width:2px;  }
    &.border-width-3{  border-bottom-width:3px;  }
  }
}
// custom switch color variations
.custom-switch{@each $name, $color in $theme-colors{@include custom-switch-variant($name, $color);}
  @each $name, $color in $colors{@include custom-switch-variant($name, $color);}
}
// custom range color variations
.custom-range{@each $name, $color in $theme-colors{@include custom-range-variant($name, $color);}
  @each $name, $color in $colors{@include custom-range-variant($name, $color);}
}
// custom control input variations
@each $name, $color in $theme-colors{@include custom-control-input-variant($name, $color);}
@each $name, $color in $colors{@include custom-control-input-variant($name, $color);}
.custom-control-input-outline{~ .custom-control-label::before{background-color:transparent !important;  box-shadow:none;}
  &:checked ~ .custom-control-label::before{@include gradient-bg(transparent);}
}
.navbar-dark{.btn-navbar,
  .form-control-navbar{background-color:lighten($sidebar-dark-bg, 5%);  border:1px solid lighten($sidebar-dark-bg, 15%);  color:lighten(color-yiq(lighten($sidebar-dark-bg, 5%)), 15%);}
  .btn-navbar{&:hover{  background-color:lighten($sidebar-dark-bg, 7.5%);  }
    &:focus{  background-color:lighten($sidebar-dark-bg, 10%);  }
  }
  .form-control-navbar + .input-group-prepend,
  .form-control-navbar + .input-group-append{> .btn-navbar{  background-color:lighten($sidebar-dark-bg, 5%);    color:$white;    border:1px solid lighten($sidebar-dark-bg, 15%);    border-left:none;  }
  }
}
@include dark-mode (){.form-control:not(.form-control-navbar):not(.form-control-sidebar),
  .custom-select,
  .custom-file-label,
  .custom-file-label::after,
  .custom-control-label::before,
  .input-group-text{background-color:$dark;  color:$white;}
  .form-control:not(.form-control-navbar):not(.form-control-sidebar):not(.is-invalid):not(:focus),
  .custom-file-label,
  .custom-file-label::after{border-color:$gray-600;}
  select{background-color:$dark;  color:$white;  border-color:$gray-600;}
  .custom-select{background:$dark $custom-select-dark-background;
    &[multiple]{  background:$dark;  }
  }
  .input-group-text{border-color:$gray-600;}
  .custom-control-input:disabled ~ .custom-control-label::before,
  .custom-control-input[disabled] ~ .custom-control-label::before{background-color:lighten($dark, 5%);  border-color:$gray-600;  color:$white;}
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus{-webkit-text-fill-color:$white;}
  .custom-range{&::-webkit-slider-runnable-track{  background-color:lighten($dark, 7.5%);  }
    &::-moz-range-track{  background-color:lighten($dark, 7.5%);  }
    &::-ms-track{  background-color:lighten($dark, 7.5%);  }
    @each $name, $color in $theme-colors-alt{  @include custom-range-variant($name, $color);  }
    @each $name, $color in $colors-alt{  @include custom-range-variant($name, $color);  }
  }
  // custom switch color variations
  .custom-switch{@each $name, $color in $theme-colors-alt{  @include custom-switch-variant($name, $color);  }
    @each $name, $color in $colors-alt{  @include custom-switch-variant($name, $color);  }
  }
  @each $name, $color in $theme-colors-alt{@include custom-control-input-variant($name, $color);}
  @each $name, $color in $colors-alt{@include custom-control-input-variant($name, $color);}
}
