//
// Mixins:Navbar
//
// Navbar Variant
@mixin navbar-variant($name, $color){.navbar-#{$name}{background-color:$color;  color:color-yiq($color);
    &.navbar-light{  .form-control-navbar{    &::placeholder{      color:rgba($gray-800, .8);      }
        &,
        + .input-group-append>.btn-navbar{      background-color:darken($color, 4%);        border-color:darken($color, 9%);        color:rgba($gray-800, .8);      }
        &:focus{      &::placeholder{        color:$gray-800;        }
          &,
          + .input-group-append .btn-navbar{        background-color:darken($color, 5%);          border-color:darken($color, 9%) !important;          color:$gray-800;        }
        }
      }
    }
    &.navbar-dark{  .form-control-navbar{    &::placeholder{      color:rgba($white, .8);      }
        &,
        + .input-group-append>.btn-navbar{      background-color:lighten($color, 4%);        border-color:lighten($color, 9%);        color:rgba($white, .8);      }
        &:focus{      &::placeholder{        color:$white;        }
          &,
          + .input-group-append .btn-navbar{        background-color:lighten($color, 5%);          border-color:lighten($color, 9%) !important;          color:$white;        }
        }
      }
    }
  }
}
