//
// Component:Modals
//
// Overlay
.modal-dialog{.overlay{display:flex;  position:absolute;  left:0;  top:0;  bottom:0;  right:0;  margin:-$modal-content-border-width;  z-index:($zindex-modal + 2);  justify-content:center;  align-items:center;  background-color:rgba($black, .7);  color:darken($gray-600, 2.5%);  @include border-radius($modal-content-border-radius);}
}

// BG Color Variations Fixes
.modal-content{&.bg-warning{.modal-header,
    .modal-footer{  border-color:$gray-800;  }
  }
  &.bg-primary,
  &.bg-secondary,
  &.bg-info,
  &.bg-danger,
  &.bg-success,{.close{  color:$white;    text-shadow:0 1px 0 $black;  }
  }
}
@include dark-mode (){.modal-header,
  .modal-footer{border-color:$gray-600;}
  .modal-content{background-color:$dark;
    &.bg-warning{  .modal-header,
      .modal-footer{    border-color:$gray-600;    }
      .close{    color:$dark !important;      text-shadow:0 1px 0 $gray-700 !important;    }
    }
    &.bg-primary,
    &.bg-secondary,
    &.bg-info,
    &.bg-danger,
    &.bg-success{  .modal-header,
      .modal-footer{    border-color:$white;    }
    }
  }
}
